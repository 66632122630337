.navigation {
    background: rgba(255, 255, 255, 0.651);
    width: 100%;
    // padding-top: 5px;
}

.navigation ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 15px;
}
.logosteam {
    max-width: 75px;
    max-height: 75px;
}

.navigation ul li {
    text-decoration: none;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 75vh;
  }
  
  .text-container h1,
  .text-container h2,
  .text-container p,
  .text-container a {
    margin-bottom: 20px;
    max-width: 600px;
  }